let myPlayer = null;
let done = false;
// 2. This code loads the IFrame Player API code asynchronously.
const tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
const firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// 3. This function creates an <iframe> (and YouTube player)
//    after the API code downloads.
let player;
let soundPlayer;
let mySoundPlayer;
function onYouTubeIframeAPIReady() {
    player = new YT.Player('player', {
        height: '720',
        width: '1280',
        controls:0,
        start: 5    ,
        videoId: 'fpOTPOhO_oA',
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        }
    });
    soundPlayer = new YT.Player('soundPlayer', {
        height: '100',
        width: '100',
        controls:0,
        start: 5    ,
        videoId: 'EjB6-VR_KQU',
        events: {
            'onReady': onSoundPlayerReady,
            'onStateChange': onSoundPlayerStateChange
        }
    });


}


// 4. The API will call this function when the video player is ready.
function onPlayerReady(event) {
    // event.target.playVideo();
    myPlayer = event.target
}
function onSoundPlayerReady(event) {
    // event.target.playVideo();
    mySoundPlayer = event.target
}
function playerPlay() {
    myPlayer.playVideo();
}
function soundPlayerPlay() {
    mySoundPlayer.playVideo();
}

// 5. The API calls this function when the player's state changes.
//    The function indicates that when playing a video (state=1),
//    the player should play for six seconds and then stop.

function onPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING && !done) {
        done = true;
    }
}
function onSoundPlayerStateChange(event) {
    if (event.data == YT.PlayerState.PLAYING && !done) {
        done = true;
    }
}
function playerStop() {
    player.stopVideo();
}
function soundPlayerStop() {
    //soundPlayer.stopVideo();
    let currentVolume = soundPlayer.getVolume();
    if (currentVolume === 0) {
        soundPlayer.pauseVideo();
        soundPlayer.unMute();
    } else {
        soundPlayer.setVolume(currentVolume - 5);
        return setTimeout((function() {
            return soundPlayerStop();
        }), 100);
    }
}
function soundPlayerRestart() {
    //soundPlayer.stopVideo();
    let currentVolume = soundPlayer.getVolume();
    soundPlayer.unMute();
    console.log(currentVolume);
    if (currentVolume === 100) {
        return soundPlayer.setVolume(100);
    } else {
        soundPlayer.setVolume(currentVolume + 5);
        return setTimeout((function() {
            return soundPlayerRestart();
        }), 100);
    }
}
