let animTl = null;

function launchLoading(wait= false) {
    animTl.set('.loading', { 'display': 'flex' });
    animTl.set('.loading', { 'display': 'flex' });
    animTl.add("start");
    animTl.to(".top, .bottom", .4, {
        y: 0,
        ease: 'power2.inOut'
    });


    animTl.add("start");


    animTl.to('.line', {
        width: '50%',
        opacity: 1,
        duration: 0.5
    }, -0.1);

    animTl.add('text-start');

    animTl.to('.top p span', 0.6, {
        marginBottom: '0',
        opacity: 1,
    }, 'text-start');

    animTl.to('.bottom p span', 0.6, {
        marginTop: '0',
        opacity: 1,
    }, 'text-start');

    animTl.to('.line', 0.4, {
        opacity: 0,
        onComplete: function () {
        },
    }, 'text-start');
    animTl.to('.border-shape', 1.2, {
        width: '150px',
        height: '150px',
        opacity: 1,
        rotation: 225,
        ease: 'power2.inOut'
    }, 'text-start');
    animTl.add('square-in');
    if (wait) {
        startWaiting();
    }

}


function startWaiting() {
    animTl.add('chevron-start');
    var chevronDuration = .8;
    animTl.to('.chevron.top', chevronDuration, {
        top: '-40px',
        ease: 'power2.inOut',
        opacity: 1
    }, 'chevron-start-=.4');

    animTl.to('.chevron.bottom', chevronDuration, {
        bottom: '-40px',
        ease: 'power2.inOut',
        opacity: 1
    }, 'chevron-start-=.4');

    animTl.to('.chevron.left', chevronDuration, {
        left: '-40px',
        ease: 'power2.inOut',
        opacity: 1
    }, 'chevron-start-=.4');

    animTl.to('.chevron.right', chevronDuration, {
        right: '-40px',
        ease: 'power2.inOut',
        opacity: 1
    }, 'chevron-start-=.4');

    animTl.add('chevron-return');
    animTl.to('.chevron.top', chevronDuration, {
        top: '-20px',
        ease: 'power2.inOut',
        opacity: 0
    }, 'chevron-return');

    animTl.to('.chevron.bottom', chevronDuration, {
        bottom: '-20px',
        ease: 'power2.inOut',
        opacity: 0
    }, 'chevron-return');

    animTl.to('.chevron.left', chevronDuration, {
        left: '-20px',
        ease: 'power2.inOut',
        opacity: 0
    }, 'chevron-return');

    animTl.to('.chevron.right', chevronDuration, {
        right: '-20px',
        ease: 'power2.inOut',
        opacity: 0
    }, 'chevron-return');
    animTl.to('.border-shape', 0.8, {
        rotation: '+=90',
        ease: 'power2.inOut',
        onComplete: function () {
            startWaiting();
        },
    });
}
function endCountdown() {

    animTl.to('.top p span', 0.6, {
        marginBottom: '-40px',
        opacity: 0,
    }, 'text-back');

    animTl.to('.bottom p span', 0.6, {
        marginTop: '-40px',
        opacity: 0,
    }, 'text-back');
    // after loaded
    animTl.to('.border-shape', 1.2, {
        width: '10px',
        height: '10px',
        opacity: 0,
        rotation: '-=270',
        ease: 'power2.inOut',
        onComplete: function () {
            clearInterval(ctnInterval)
        }
    }, 'text-back');

    animTl.add('curtain-end');
    animTl.to(".top", 0.4, {
        y: '-100%',
        ease: 'power2.inOut'
    }, 'curtain-end');
    animTl.to(".bottom", 0.4, {
        y: '100%',
        ease: 'power2.inOut',
        onComplete: function () {
            document.body.classList.remove('myloading');
            playerPlay();
        }
    }, 'curtain-end');
    animTl.set('.loading', { 'display': 'none' });

}
jQuery(document).ready(function ($) {
    animTl = gsap.timeline({paused: true, defaults: {duration: 1}});
    launchLoading(true)

});
