// const server = 'http://localhost:4001'
const server = 'https://poc.io.ideative.live/'
const socket = io(server);
let galleryOpen = false;
let $gallery = $("#gallery");
let lgData = null;
let currentSlide = 1;
let slideTimeOut = null;
let ctnInterval = null;

/**
 *
 */
function launchCountdown() {
    var timer2 = "2:00";
    $('#cnt-txt').text('0' + timer2);
    ctnInterval = setInterval(function() {
        var timer = timer2.split(':');
        //by parsing integer, I avoid all extra string processing
        var minutes = parseInt(timer[0], 10);
        var seconds = parseInt(timer[1], 10);
        --seconds;
        minutes = (seconds < 0) ? --minutes : minutes;
        if (minutes < 0) clearInterval(ctnInterval);
        seconds = (seconds < 0) ? 59 : seconds;
        seconds = (seconds < 10) ? '0' + seconds : seconds;
        //minutes = (minutes < 10) ?  minutes : minutes;
        $('.countdown').html(minutes + ':' + seconds);
        timer2 = minutes + ':' + seconds;
        if (minutes > 0 || seconds >= 0) {
            $('#cnt-txt').text('0' + timer2)
        } else {
            clearInterval(ctnInterval)
        }

    }, 1000);
}

/**
 *
 */
function launchSlide() {
    $('body').removeClass('step2')
    $('.slide_' + currentSlide).siblings().hide().end().fadeIn()
    if (currentSlide < 9) {
        currentSlide++;
    } else {
        currentSlide = 1;
    }

    slideTimeOut = setTimeout(launchSlide, 4000);
}

/**
 *
 */
function stopSlide() {
    $('body').addClass('step2')
    clearTimeout(slideTimeOut)
}

/**
 *
 * @param channel
 * @param message
 */
function publish(channel, message){
    // console.log('publishing ' + message + ' on channel ' + channel);
    socket.emit(channel, message);
}

/**
 *
 * @param channel
 * @param callback
 */
function subscribe(channel, callback){
    socket.on(channel, callback);
}

/**
 *
 * @param msg
 * @param type
 */
function showToast(msg, type) {
    $.toast({
        heading: 'Message',
        text: msg,
        icon: type
    })
}

/**
 *
 */
jQuery(document).ready(function($) {
    socket.on('init', function (data){
        // console.log('init', data);
        socket.emit('call', { my: 'data' });
        showToast('Now connected to ' + server, 'success')
    });

    subscribe('pong', function(data){
        // console.log('got pong', data);

    });
    subscribe('action', function(data){
        console.log('received action');
        if(data.todo === 'message'){
            showToast(data.value, 'info')
        }

        if (data.todo === 'galleryTo' && data.numImage) {
            const current = data.numImage;
            if (!galleryOpen) {
                $('#gallery-item_' + current).trigger('click')
                galleryOpen = true;
            } else {
                lgData.slide(current - 1);
            }
            $(".controls")
                .find(`img[data-num='${current}']`)
                .siblings()
                .removeClass('show')
                .end()
                .toggleClass('show')
        } else if (data.todo === 'fadeOutMusic') {
            soundPlayerStop()
        } else if (data.todo === 'launchCountdown') {

            setTimeout(() => {
                animTl.play()
                launchCountdown();
                $('.titles h2').fadeOut();
            }, 2700)

            soundPlayerPlay()

        } else if (data.todo === 'startTheShow') {
                $('#player').addClass('show')
                endCountdown();
                stopSlide();
                setTimeout( () => {
                    $('.titles h2').fadeOut().text('LIVE').addClass('glow').fadeIn();
                }, 3000)
        } else if (data.todo === 'endTheShow') {
            $('#player').removeClass('show')
            playerStop();
            $('.titles h2').fadeOut().text('is over - Thanks for watching').removeClass('glow').fadeIn();
            mySoundPlayer.playVideo();
            soundPlayerRestart();
            launchSlide();

        } else if (data.todo === 'closeGallery') {
            if ($(".lg-outer").length) {
                lgData.destroy()
                galleryOpen = false;
            }
        } else if (data.todo === 'launchWeb') {
            window.open('https://www.mbandf.com/en/machines/legacy-machines/');
        }


    });
    $(document).ready(function() {
        launchSlide();
        $gallery.lightGallery({
            controls: false,
            cssEasing : 'cubic-bezier(0.25, 0, 0.25, 1)'
        });
        lgData = $gallery.data("lightGallery");
    });

});
